<!-- @component Embed a form created using Fillout.com as a popup. -->

<script lang="ts">
  import { createDialog, melt } from "@melt-ui/svelte";
  import { XIcon } from "lucide-svelte";
  import { createEventDispatcher } from "svelte";
  import { cubicOut } from "svelte/easing";
  import { fade } from "svelte/transition";

  export let formId: string;
  export let title: string;
  export let open: boolean;

  const dispatch = createEventDispatcher<{ close: void }>();

  const dim = "max-w-screen-md w-full h-full max-h-[720px]";

  const {
    elements: { overlay, content, close, portalled },
    states,
  } = createDialog({
    onOpenChange: ({ next }) => {
      // Ignore changes to `open`, and propagate them upward as events instead.
      // This turns the component into a controlled comonent, since the store's
      // value is always consistent with the `open` prop.
      if (open && !next) dispatch("close");
      return open;
    },
  });

  $: states.open.set(open);
</script>

{#if open}
  <div use:melt={$portalled}>
    <div
      class="fixed z-40 inset-0 flex flex-col justify-center items-center p-4"
      transition:fade={{ duration: 500, easing: cubicOut }}
    >
      <div use:melt={$overlay} class="absolute inset-0 bg-black/50 -z-10" />
      <div
        class="relative flex flex-col {dim} bg-white/5 border border-white/10 rounded-lg"
        use:melt={$content}
      >
        <iframe
          src="https://embed.fillout.com/t/{formId}?fillout-embed-type=popup&fillout-embed-dynamic-resize=true"
          allow="microphone; camera; geolocation"
          class="w-full h-full border-0 bg-black rounded-lg"
          {title}
        />
        <button
          class="absolute w-8 h-8 p-1 -right-3 -top-3 rounded-full bg-black border border-white/20"
          use:melt={$close}
        >
          <XIcon class="h-full w-full" />
        </button>
      </div>
    </div>
  </div>
{/if}
